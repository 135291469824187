.react-time-picker {
    padding-inline: 16px;
    border: 1px solid var(--chakra-colors-gray-200);
    border-radius: var(--chakra-radii-sm);
    min-height: var(--chakra-sizes-10);
    font-size: medium;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
}

.react-time-picker__wrapper {
    display: flex;
    place-content: center;
    flex-direction: row;
}

.react-time-picker--disabled {
    color: var(--chakra-colors-text-disabled);

    .react-time-picker__clear-button {
        color: black;
        cursor: auto;
    }
}
.react-time-picker__inputGroup {
    flex-grow: 1;
    min-width: max-content;
}

.react-time-picker__inputGroup__input {
    background-color: white;
}

.react-time-picker-invalid {
    border-color: #e53e3e;
    box-shadow: 0 0 0 1px #e53e3e;
}
