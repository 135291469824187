html,
body {
    margin: 0;
    padding: 0;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
.spin-loading {
    animation: spin 2s linear infinite;
}

.chakra-select__wrapper select {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
